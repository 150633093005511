import React from "react";
import styled from "styled-components";

const FooterNav = styled.div`
  background-color: white;
  height: ${(props) => (props.footerheight ? props.footerheight : "125px")};
  display: flex;
  align-items: center;
`;

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RightLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Footertext = styled.div`
  font-size: 10px;
`;

const Footer = ({ height = "125px" }) => {
  return (
    <FooterNav footerheight={height}>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <RightLeft>
              <LeftSide>
                <Footertext>
                  <b>Kompott Musig:</b>
                </Footertext>
                <Footertext>Ines Bill - Flöten, Kornett, Gitarre</Footertext>
                <Footertext>Annemarie Graber - Akkordeon</Footertext>
                <Footertext>Maja Iff - Cello, Euphonium, Sax</Footertext>
                <Footertext>
                  Christine Steinmann - Perkussion, Geige, Flöte
                </Footertext>
              </LeftSide>
            </RightLeft>
          </div>
        </div>
      </div>
    </FooterNav>
  );
};

export default Footer;
